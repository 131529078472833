<!-- 提现管理 -->
<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 头部提现 -->
      <el-row style="width: 100%; margin: 0 0 15px 0">
        <el-col :span="12">
          <span class="fontNav">{{ selfOrgName }}</span>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button type="primary" size="small" @click="WithdrawalClick"
            >提现</el-button
          >
        </el-col>
      </el-row>

      <!-- 描述列表区域 -->
      <el-descriptions
        class="descriptions-class"
        :title="descriptionTitle"
        :column="3"
        size="large"
        border
      >
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">所属平台商</div>
          </template>
          <span>{{ orgName }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">商户号</div>
          </template>
          <span>{{ merchantId }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">应用名称</div>
          </template>
          <span>{{ userApp }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">累计金额</div>
          </template>
          <el-tag type="success" size="small">￥ {{ orderBalance }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">今日收入</div>
          </template>
          <el-tag type="success" size="small"
            >￥ {{ todayOrderBalance }}</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">可用余额</div>
          </template>
          <el-tag size="small">￥{{ downBalance }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">待审核</div>
          </template>
          <el-tag size="small">￥{{ processBalance }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">结算时间</div>
          </template>
          {{ paymentTime }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <span>时间范围：</span>
            <el-date-picker
              v-model="alarTime"
              unlink-panels
              type="datetimerange"
              size="small"
              range-separator="至"
              start-placeholder="起始时间"
              end-placeholder="结束时间"
              format="YYYY-MM-DD HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
              style="margin-top: 6px; vertical-align: bottom; margin-right: 4px"
              @change="pickerTimeChange"
            />
            <el-select
              clearable
              class="search-input"
              placeholder="请选择收支类型"
              size="small"
              v-model="paymentType"
              @change="handleStateChange"
            >
              <el-option
                v-for="item in paymentTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                {{ item.label }}
              </el-option>
            </el-select>
            <el-select
              clearable
              class="search-input"
              placeholder="请选择交易类型"
              size="small"
              v-model="transType"
              @change="handleStateChangeType"
            >
              <el-option
                v-for="item in transactionType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                {{ item.label }}
              </el-option>
            </el-select>
            <el-button type="primary" size="small" @click="searchClick"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="530px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" align="center" width="80" />
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          >
          </el-table-column>
          <el-table-column label="操作" align="center">
            <!--            <template #default="{ row }">-->
            <!--              <el-button type="text" @click="editClick(row)">编辑</el-button>-->
            <!--              <el-divider direction="vertical"></el-divider>-->
            <!--              <el-popconfirm-->
            <!--                  confirm-button-text="确定"-->
            <!--                  cancel-button-text="取消"-->
            <!--                  @confirm="confirmEvent(row)"-->
            <!--                  :title="'确认是否删除' + '“' + row.deviceId + '”' + '？'"-->
            <!--              >-->
            <!--                <template #reference>-->
            <!--                  <el-button type="text">删除</el-button>-->
            <!--                </template>-->
            <!--              </el-popconfirm>-->
            <!--            </template>-->
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
      <!-- 提现弹框 -->
      <el-dialog width="30%" title="商户余额提现" v-model="tixianVisible">
        <el-form
          label-width="120px"
          :inline="true"
          :model="FormData"
          :rules="rules"
          ref="ruleForm"
        >
          <el-row :gutter="20">
            <el-col :span="22">
              <el-form-item label="商户名称：">
                <span>{{ selfOrgName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="可提现金额(元)：">
                <span>{{ downBalance }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="本次提现(元)：">
                <el-input-number
                  v-model="FormData.num"
                  :min="3"
                  :max="5000"
                  size="small"
                  @change="handleChange"
                />
                <el-button class="wholeClass" size="small" @click="wholeClick"
                  >全部提现</el-button
                >
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="收款账户：" prop="chargeScheme">
                <el-radio-group v-model="FormData.chargeScheme">
                  <el-radio
                    v-for="item in timingTypeOptions"
                    :key="item.value"
                    :label="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <template #footer>
          <span class="zuClass">注：单次提现限额5000元,最低提现3元</span>
          <span class="dialog-footer">
            <el-button size="small" @click="resetEditForm">取消 </el-button>
            <el-button
              size="small"
              type="primary"
              @click="submitEditForm('ruleForm')"
              >提现
            </el-button>
          </span>
        </template>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { computed, reactive, ref, toRefs } from "vue";
import { getCurrentInstance, onMounted } from "vue-demi";
import { debounces } from "@/common/utils";
import { requestApi } from "@/utils/requestApi";
import {
  selectPagesApi,
  getAllApi,
} from "@/api/financeManagement/financeManagement.js";
import pageNation from "@/components/base/pageNation.vue";
export default {
  name: "WithdrawManagement",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      userInfo: null,
      selfOrgName: null, // 商户名称
      orgName: null, //所属商户
      merchantId: null, // 商户号
      userApp: null, //应用名称
      orderBalance: null, // 累计金额
      todayOrderBalance: null, //当日累计收入
      downBalance: null, // 可用余额
      processBalance: null, //待审核金额
      paymentTime: null, //结算时间
      financesBalance: null, // 提现金额
      tixianVisible: false, // 提现弹框
      FormData: {
        num: 3, // 提现的金额
        chargeScheme: null, // 收款账号
      }, //
      rules: {
        chargeScheme: {
          required: true,
          message: "请选择收款账号",
          trigger: ["blur", "change"],
        },
      },
      // 计时类型
      timingTypeOptions: [{ value: 0, label: "特瑞萨账号" }],

      tableData: [], // 表格数据
      alarTime: [], // 时间范围
      paymentType: null, // 支出类型
      transType: null, //交易类型
      total: 0,
      pageSize: 10,
      currentPage: 1,
      tableColumnData: [
        {
          prop: "deviceMac",
          label: "账户",
        },
        {
          prop: "deviceSignal",
          label: "业务类型",
        },
        {
          prop: "deviceVersion",
          label: "交易充电站",
        },
        {
          prop: "deviceImei",
          label: "业务单号",
        },
        {
          prop: "deviceAgency",
          label: "金额(元)",
        },
        {
          prop: "orgName",
          label: "交易前余额(元)",
        },
        {
          prop: "deviceAddress",
          label: "交易后余额(元)",
        },
        {
          prop: "deviceStatus",
          label: "交易对手",
        },
        {
          prop: "chargeName",
          label: "入账时间",
        },
      ],
      selection: [],
      paymentTypeList: [
        { value: 0, label: "收入" },
        { value: 1, label: "支出 " },
      ],
      // 交易类型
      transactionType: [
        { value: 0, label: "充电订单收入" },
        { value: 1, label: "充电分成收入 " },
        { value: 2, label: "订单退款" },
        { value: 3, label: "月卡收入" },
        { value: 4, label: "商家提现" },
      ],
    });
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      methods.selectPages();
      methods.selectPagesAll();
    });
    // 节流搜索按钮
    const searchInput = debounces(() => {}, 500);
    const methods = {
      // 商户余额查询
      async selectPages() {
        await requestApi(
          () => {
            const params = {
              merchantId: data.userInfo.merchantId,
            };
            return selectPagesApi(params);
          },
          (res) => {
            if (res.code === 200) {
              proxy.selfOrgName = res.data.selfOrgName;
              proxy.orgName = res.data.orgName;
              proxy.merchantId = res.data.merchantId;
              proxy.userApp = res.data.userApp;
              proxy.orderBalance = res.data.orderBalance;
              proxy.todayOrderBalance = res.data.todayOrderBalance;
              proxy.downBalance = res.data.downBalance;
              proxy.processBalance = res.data.processBalance;
              proxy.paymentTime = res.data.paymentTime;
              proxy.downBalance = res.data.downBalance;
            }
          }
        );
      },
      // 商户余额分页查询
      async selectPagesAll() {
        await requestApi(
          () => {
            const params = {
              current: data.currentPage,
              size: data.pageSize,
              startTime:data.alarTime[0],
              endTime:data.alarTime[1],
              incomeType: data.paymentType,
              transactionType: data.transType,
              merchantId: data.userInfo.merchantId,
            };
            return getAllApi(params);
          },
          (res) => {
            if (res.code === 200) {
              data.tableData = res.data.records;
              data.currentPage = res.data.current;
              data.pageSize = res.data.size;
              data.total = res.data.total;
            }
          }
        );
      },
      // 提现按钮
      WithdrawalClick() {
        proxy.tixianVisible = true;
        proxy.FormData.num = 3;
      },
      // 输入提现按钮
      handleChange(num) {
        proxy.FormData.num = num;
      },
      // 全部提现
      wholeClick() {
        proxy.FormData.num = proxy.downBalance;
        proxy.$msgbox
          .confirm("确认是否全部提现?", "提现", {
            closeOnClickModal: false,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(async () => {
            methods.submitEditForm(formName);
          })
          .catch({});
      },
      // 取消
      resetEditForm() {
        proxy.tixianVisible = false;
      },
      // 确认提现
      async submitEditForm(formName) {
        proxy.$refs[formName].validate(async (valid) => {
          if (valid) {
            await methods.resetEditForm();
          }
        });
      },
      // 表格分页 改变每页数量时触发
      handleSizeChange(val) {
        proxy.pageSize = val;
        methods.selectPagesAll();
      },
      pickerTimeChange(val) {
        methods.selectPagesAll();
      },
      // 下拉框交易类型查询
      handleStateChange() {
        methods.selectPagesAll();
      },
      // 下拉框交易类型查询
      handleStateChangeType() {
        methods.selectPagesAll();
      },
      // 表格分页 改变页数时触发
      handleCurrentChange(val) {
        proxy.currentPage = val;
        methods.selectPagesAll();
      },
    };

    return {
      ...toRefs(data),
      ...methods,
      searchInput,
    };
  },
};
</script>

<style lang="less" scoped>
.el-descriptions {
  margin-top: 20px;
}
.cell-item {
  display: flex;
  align-items: center;
}
.fontNav {
  font-size: 18px;
  font-weight: 18px;
}
.descriptions-class {
  margin-top: 20px;
  margin-bottom: 12px;
}
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
}
.page-container {
  width: 100%;
  //margin-top: -28px;
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}
.wholeClass {
  margin-left: 40px;
}
.zuClass {
  font-size: 10px;
  color: rgb(177, 65, 65);
  margin-right: 200px;
}
</style>
